import React from "react"

import { Box, Link, Typography } from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"
import { Helmet } from "react-helmet"

import MainShell from "../components/main-shell"
import ReturnForm from "../components/return-form"

const TermsPage = () => {
  return (
    <MainShell breadcrumbs={[{ label: `Algemene voorwaarden` }]}>
      <Helmet>
        <title>Algemene voorwaarden Todocards.nl</title>

        <meta
          name="description"
          content="Onze algemene voorwaarden zijn van toepassing op alle bestellingen die bij ons gedaan worden."
        />
      </Helmet>

      <Typography variant="h1">Algemene voorwaarden Todocards.nl</Typography>

      <Typography variant="subtitle2" paragraph={true}>
        Datum: 19 maart 2020
        <br />
        Versie: 1.0
        <br />
        <Link
          href="/algemene_voorwaarden_todocards_nl_versie_1_0.pdf"
          target="_blank"
          underline="always"
        >
          Download in PDF-formaat
        </Link>
      </Typography>

      <Typography variant="h2" gutterBottom>
        Inhoudsopgave
      </Typography>

      <Typography paragraph={true}>
        Artikel 1 - Definities
        <br />
        Artikel 2 - Identiteit van de ondernemer
        <br />
        Artikel 3 - Toepasselijkheid
        <br />
        Artikel 4 - Het aanbod
        <br />
        Artikel 5 - De overeenkomst
        <br />
        Artikel 6 - Herroepingsrecht
        <br />
        Artikel 7 - Verplichtingen van de consument tijdens de bedenktijd
        <br />
        Artikel 8 - Uitoefening van het herroepingsrecht door de consument en
        kosten daarvan
        <br />
        Artikel 9 - Verplichtingen van de ondernemer bij herroeping
        <br />
        Artikel 10 - Uitsluiting herroepingsrecht
        <br />
        Artikel 11 - De prijs
        <br />
        Artikel 12 - Nakoming en extra garantie
        <br />
        Artikel 13 - Levering en uitvoering
        <br />
        Artikel 14 - Duurtransacties: duur, opzegging en verlenging
        <br />
        Artikel 15 - Betaling
        <br />
        Artikel 16 - Klachtenregeling
        <br />
        Artikel 17 - Geschillen
        <br />
        Artikel 18 - Aanvullende of afwijkende bepalingen
        <br />
        Bijlage I - Modelformulier voor herroeping
      </Typography>

      <Typography variant="h2" gutterBottom>
        Artikel 1 – Definities
      </Typography>

      <Typography paragraph={true}>
        In deze voorwaarden wordt verstaan onder:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. <span className="bold">Aanvullende overeenkomst</span>: een
          overeenkomst waarbij de consument producten, digitale inhoud en/of
          diensten verwerft in verband met een overeenkomst op afstand en deze
          zaken, digitale inhoud en/of diensten door de ondernemer worden
          geleverd of door een derde partij op basis van een afspraak tussen die
          derde en de ondernemer;
        </Typography>

        <Typography paragraph={true}>
          2. <span className="bold">Bedenktijd</span>: de termijn waarbinnen de
          consument gebruik kan maken van zijn herroepingsrecht;
        </Typography>

        <Typography paragraph={true}>
          3. <span className="bold">Consument</span>: de natuurlijke persoon die
          niet handelt voor doeleinden die verband houden met zijn handels-,
          bedrijfs-, ambachts- of beroepsactiviteit;
        </Typography>

        <Typography paragraph={true}>
          4. <span className="bold">Dag</span>: kalenderdag;
        </Typography>

        <Typography paragraph={true}>
          5. <span className="bold">Digitale inhoud</span>: gegevens die in
          digitale vorm geproduceerd en geleverd worden;
        </Typography>

        <Typography paragraph={true}>
          6. <span className="bold">Duurovereenkomst</span>: een overeenkomst
          die strekt tot de regelmatige levering van zaken, diensten en/of
          digitale inhoud gedurende een bepaalde periode;
        </Typography>

        <Typography paragraph={true}>
          7. <span className="bold">Duurzame gegevensdrager</span>: elk
          hulpmiddel - waaronder ook begrepen e-mail - dat de consument of
          ondernemer in staat stelt om informatie die aan hem persoonlijk is
          gericht, op te slaan op een manier die toekomstige raadpleging of
          gebruik gedurende een periode die is afgestemd op het doel waarvoor de
          informatie is bestemd, en die ongewijzigde reproductie van de
          opgeslagen informatie mogelijk maakt;
        </Typography>

        <Typography paragraph={true}>
          8. <span className="bold">Herroepingsrecht</span>: de mogelijkheid van
          de consument om binnen de bedenktijd af te zien van de overeenkomst op
          afstand;
        </Typography>

        <Typography paragraph={true}>
          9. <span className="bold">Ondernemer</span>: de natuurlijke of
          rechtspersoon die producten, (toegang tot) digitale inhoud en/of
          diensten op afstand aan consumenten aanbiedt;
        </Typography>

        <Typography paragraph={true}>
          10. <span className="bold">Overeenkomst op afstand</span>: een
          overeenkomst die tussen de ondernemer en de consument wordt gesloten
          in het kader van een georganiseerd systeem voor verkoop op afstand van
          producten, digitale inhoud en/of diensten, waarbij tot en met het
          sluiten van de overeenkomst uitsluitend of mede gebruik gemaakt wordt
          van één of meer technieken voor communicatie op afstand;
        </Typography>

        <Typography paragraph={true}>
          11. <span className="bold">Modelformulier voor herroeping</span>: het
          in Bijlage I van deze voorwaarden opgenomen Europese modelformulier
          voor herroeping.
        </Typography>

        <Typography paragraph={true}>
          12.{" "}
          <span className="bold">Techniek voor communicatie op afstand</span>:
          middel dat kan worden gebruikt voor het sluiten van een overeenkomst,
          zonder dat consument en ondernemer gelijktijdig in dezelfde ruimte
          hoeven te zijn samengekomen.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 2 - Identiteit van de ondernemer
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl
        <br />
        Mr. Tripkade 18
        <br />
        3571 SX Utrecht
        <br />
        Nederland
      </Typography>

      <Typography paragraph={true}>
        E-mailadres: info@todocards.nl
        <br />
        KvK-nummer: 30221205
        <br />
        Btw-identificatienummer: NL001179039B37
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl is een handelsnaam van TD Media.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Artikel 3 - Toepasselijkheid
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Deze algemene voorwaarden zijn van toepassing op elk aanbod van de
          ondernemer en op elke tot stand gekomen overeenkomst op afstand tussen
          ondernemer en consument.
        </Typography>

        <Typography paragraph={true}>
          2. Voordat de overeenkomst op afstand wordt gesloten, wordt de tekst
          van deze algemene voorwaarden aan de consument beschikbaar gesteld.
          Indien dit redelijkerwijs niet mogelijk is, zal de ondernemer voordat
          de overeenkomst op afstand wordt gesloten, aangeven op welke wijze de
          algemene voorwaarden bij de ondernemer zijn in te zien en dat zij op
          verzoek van de consument zo spoedig mogelijk kosteloos worden
          toegezonden.
        </Typography>

        <Typography paragraph={true}>
          3. Indien de overeenkomst op afstand elektronisch wordt gesloten, kan
          in afwijking van het vorige lid en voordat de overeenkomst op afstand
          wordt gesloten, de tekst van deze algemene voorwaarden langs
          elektronische weg aan de consument ter beschikking worden gesteld op
          zodanige wijze dat deze door de consument op een eenvoudige manier kan
          worden opgeslagen op een duurzame gegevensdrager. Indien dit
          redelijkerwijs niet mogelijk is, zal voordat de overeenkomst op
          afstand wordt gesloten, worden aangegeven waar van de algemene
          voorwaarden langs elektronische weg kan worden kennisgenomen en dat
          zij op verzoek van de consument langs elektronische weg of op andere
          wijze kosteloos zullen worden toegezonden.
        </Typography>

        <Typography paragraph={true}>
          4. Voor het geval dat naast deze algemene voorwaarden tevens
          specifieke product- of dienstenvoorwaarden van toepassing zijn, is het
          tweede en derde lid van overeenkomstige toepassing en kan de consument
          zich in geval van tegenstrijdige voorwaarden steeds beroepen op de
          toepasselijke bepaling die voor hem het meest gunstig is.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 4 - Het aanbod
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Indien een aanbod een beperkte geldigheidsduur heeft of onder
          voorwaarden geschiedt, wordt dit nadrukkelijk in het aanbod vermeld.
        </Typography>

        <Typography paragraph={true}>
          2. Het aanbod bevat een volledige en nauwkeurige omschrijving van de
          aangeboden producten, digitale inhoud en/of diensten. De beschrijving
          is voldoende gedetailleerd om een goede beoordeling van het aanbod
          door de consument mogelijk te maken. Als de ondernemer gebruik maakt
          van afbeeldingen, zijn deze een waarheidsgetrouwe weergave van de
          aangeboden producten, diensten en/of digitale inhoud. Kennelijke
          vergissingen of kennelijke fouten in het aanbod binden de ondernemer
          niet.
        </Typography>

        <Typography paragraph={true}>
          3. Elk aanbod bevat zodanige informatie, dat voor de consument
          duidelijk is wat de rechten en verplichtingen zijn, die aan de
          aanvaarding van het aanbod zijn verbonden.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 5 - De overeenkomst
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De overeenkomst komt, onder voorbehoud van het bepaalde in lid 4,
          tot stand op het moment van aanvaarding door de consument van het
          aanbod en het voldoen aan de daarbij gestelde voorwaarden.
        </Typography>

        <Typography paragraph={true}>
          2. Indien de consument het aanbod langs elektronische weg heeft
          aanvaard, bevestigt de ondernemer onverwijld langs elektronische weg
          de ontvangst van de aanvaarding van het aanbod. Zolang de ontvangst
          van deze aanvaarding niet door de ondernemer is bevestigd, kan de
          consument de overeenkomst ontbinden.
        </Typography>

        <Typography paragraph={true}>
          3. Indien de overeenkomst elektronisch tot stand komt, treft de
          ondernemer passende technische en organisatorische maatregelen ter
          beveiliging van de elektronische overdracht van data en zorgt hij voor
          een veilige web omgeving. Indien de consument elektronisch kan
          betalen, zal de ondernemer daartoe passende veiligheidsmaatregelen in
          acht nemen.
        </Typography>

        <Typography paragraph={true}>
          4. De ondernemer kan zich binnen wettelijke kaders - op de hoogte
          stellen of de consument aan zijn betalingsverplichtingen kan voldoen,
          alsmede van al die feiten en factoren die van belang zijn voor een
          verantwoord aangaan van de overeenkomst op afstand. Indien de
          ondernemer op grond van dit onderzoek goede gronden heeft om de
          overeenkomst niet aan te gaan, is hij gerechtigd gemotiveerd een
          bestelling of aanvraag te weigeren of aan de uitvoering bijzondere
          voorwaarden te verbinden.
        </Typography>

        <Typography paragraph={true}>
          5. De ondernemer zal uiterlijk bij levering van het product, de dienst
          of digitale inhoud aan de consument de volgende informatie,
          schriftelijk of op zodanige wijze dat deze door de consument op een
          toegankelijke manier kan worden opgeslagen op een duurzame
          gegevensdrager, meesturen:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. het bezoekadres van de vestiging van de ondernemer waar de
            consument met klachten terecht kan;
          </Typography>

          <Typography paragraph={true}>
            b. de voorwaarden waaronder en de wijze waarop de consument van het
            herroepingsrecht gebruik kan maken, dan wel een duidelijke melding
            inzake het uitgesloten zijn van het herroepingsrecht;
          </Typography>

          <Typography paragraph={true}>
            c. de informatie over garanties en bestaande service na aankoop;
          </Typography>

          <Typography paragraph={true}>
            d. de prijs met inbegrip van alle belastingen van het product,
            dienst of digitale inhoud; voor zover van toepassing de kosten van
            aflevering; en de wijze van betaling, aflevering of uitvoering van
            de overeenkomst op afstand;
          </Typography>

          <Typography paragraph={true}>
            e. de vereisten voor opzegging van de overeenkomst indien de
            overeenkomst een duur heeft van meer dan één jaar of van onbepaalde
            duur is;
          </Typography>

          <Typography paragraph={true}>
            f. indien de consument een herroepingsrecht heeft, het
            modelformulier voor herroeping.
          </Typography>
        </Box>

        <Typography paragraph={true}>
          6. In geval van een duurtransactie is de bepaling in het vorige lid
          slechts van toepassing op de eerste levering.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 6 - Herroepingsrecht
      </Typography>

      <Typography variant="h5" component="h3" paragraph={true}>
        Bij producten:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De consument kan een overeenkomst met betrekking tot de aankoop van
          een product gedurende een bedenktijd van 14 dagen zonder opgave van
          redenen ontbinden. De ondernemer mag de consument vragen naar de reden
          van herroeping, maar deze niet tot opgave van zijn reden(en)
          verplichten.
        </Typography>

        <Typography paragraph={true}>
          2. De in lid 1 genoemde bedenktijd gaat in op de dag nadat de
          consument, of een vooraf door de consument aangewezen derde, die niet
          de vervoerder is, het product heeft ontvangen, of:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. als de consument in eenzelfde bestelling meerdere producten heeft
            besteld: de dag waarop de consument, of een door hem aangewezen
            derde, het laatste product heeft ontvangen. De ondernemer mag, mits
            hij de consument hier voorafgaand aan het bestelproces op duidelijke
            wijze over heeft geïnformeerd, een bestelling van meerdere producten
            met een verschillende levertijd weigeren.
          </Typography>

          <Typography paragraph={true}>
            b. als de levering van een product bestaat uit verschillende
            zendingen of onderdelen: de dag waarop de consument, of een door hem
            aangewezen derde, de laatste zending of het laatste onderdeel heeft
            ontvangen;
          </Typography>

          <Typography paragraph={true}>
            c. bij overeenkomsten voor regelmatige levering van producten
            gedurende een bepaalde periode: de dag waarop de consument, of een
            door hem aangewezen derde, het eerste product heeft ontvangen.
          </Typography>
        </Box>
      </Box>

      <Typography variant="h5" component="h3" paragraph={true}>
        Bij diensten en digitale inhoud die niet op een materiële drager is
        geleverd:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          3. De consument kan een dienstenovereenkomst en een overeenkomst voor
          levering van digitale inhoud die niet op een materiële drager is
          geleverd gedurende 14 dagen zonder opgave van redenen ontbinden. De
          ondernemer mag de consument vragen naar de reden van herroeping, maar
          deze niet tot opgave van zijn reden(en) verplichten.
        </Typography>

        <Typography paragraph={true}>
          4. De in lid 3 genoemde bedenktijd gaat in op de dag die volgt op het
          sluiten van de overeenkomst.
        </Typography>
      </Box>

      <Typography variant="h5" component="h3" paragraph={true}>
        Verlengde bedenktijd voor producten, diensten en digitale inhoud die
        niet op een materiële drager is geleverd bij niet informeren over
        herroepingsrecht:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          5. Indien de ondernemer de consument de wettelijk verplichte
          informatie over het herroepingsrecht of het modelformulier voor
          herroeping niet heeft verstrekt, loopt de bedenktijd af twaalf maanden
          na het einde van de oorspronkelijke, overeenkomstig de vorige leden
          van dit artikel vastgestelde bedenktijd.
        </Typography>

        <Typography paragraph={true}>
          6. Indien de ondernemer de in het voorgaande lid bedoelde informatie
          aan de consument heeft verstrekt binnen twaalf maanden na de
          ingangsdatum van de oorspronkelijke bedenktijd, verstrijkt de
          bedenktijd 14 dagen na de dag waarop de consument die informatie heeft
          ontvangen.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 7 - Verplichtingen van de consument tijdens de bedenktijd
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Tijdens de bedenktijd zal de consument zorgvuldig omgaan met het
          product en de verpakking. Hij zal het product slechts uitpakken of
          gebruiken in de mate die nodig is om de aard, de kenmerken en de
          werking van het product vast te stellen. Het uitgangspunt hierbij is
          dat de consument het product slechts mag hanteren en inspecteren zoals
          hij dat in een winkel zou mogen doen.
        </Typography>

        <Typography paragraph={true}>
          2. De consument is alleen aansprakelijk voor waardevermindering van
          het product die het gevolg is van een manier van omgaan met het
          product die verder gaat dan toegestaan in lid 1.
        </Typography>

        <Typography paragraph={true}>
          3. De consument is niet aansprakelijk voor waardevermindering van het
          product als de ondernemer hem niet voor of bij het sluiten van de
          overeenkomst alle wettelijk verplichte informatie over het
          herroepingsrecht heeft verstrekt.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 8 - Uitoefening van het herroepingsrecht door de consument en
        kosten daarvan
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Als de consument gebruik maakt van zijn herroepingsrecht, meldt hij
          dit binnen de bedenktermijn door middel van het modelformulier voor
          herroeping of op andere ondubbelzinnige wijze aan de ondernemer.
        </Typography>

        <Typography paragraph={true}>
          2. Zo snel mogelijk, maar binnen 14 dagen vanaf de dag volgend op de
          in lid 1 bedoelde melding, zendt de consument het product terug, of
          overhandigt hij dit aan (een gemachtigde van) de ondernemer. Dit hoeft
          niet als de ondernemer heeft aangeboden het product zelf af te halen.
          De consument heeft de terugzendtermijn in elk geval in acht genomen
          als hij het product terugzendt voordat de bedenktijd is verstreken.
        </Typography>

        <Typography paragraph={true}>
          3. De consument zendt het product terug met alle geleverde toebehoren,
          indien redelijkerwijs mogelijk in originele staat en verpakking, en
          conform de door de ondernemer verstrekte redelijke en duidelijke
          instructies.
        </Typography>

        <Typography paragraph={true}>
          4. Het risico en de bewijslast voor de juiste en tijdige uitoefening
          van het herroepingsrecht ligt bij de consument.
        </Typography>

        <Typography paragraph={true}>
          5. De ondernemer draagt de rechtstreekse kosten van het terugzenden
          van het product.
        </Typography>

        <Typography paragraph={true}>
          6. Indien de consument herroept na eerst uitdrukkelijk te hebben
          verzocht dat de verrichting van de dienst of de levering van gas,
          water of elektriciteit die niet gereed voor verkoop zijn gemaakt in
          een beperkt volume of bepaalde hoeveelheid aanvangt tijdens de
          bedenktijd, is de consument de ondernemer een bedrag verschuldigd dat
          evenredig is aan dat gedeelte van de verbintenis dat door de
          ondernemer is nagekomen op het moment van herroeping, vergeleken met
          de volledige nakoming van de verbintenis.
        </Typography>

        <Typography paragraph={true}>
          7. De consument draagt geen kosten voor de uitvoering van diensten of
          de levering van water, gas of elektriciteit, die niet gereed voor
          verkoop zijn gemaakt in een beperkt volume of hoeveelheid, of tot
          levering van stadsverwarming, indien:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. de ondernemer de consument de wettelijk verplichte informatie
            over het herroepingsrecht, de kostenvergoeding bij herroeping of het
            modelformulier voor herroeping niet heeft verstrekt, of;
          </Typography>

          <Typography paragraph={true}>
            b. de consument niet uitdrukkelijk om de aanvang van de uitvoering
            van de dienst of levering van gas, water, elektriciteit of
            stadsverwarming tijdens de bedenktijd heeft verzocht.
          </Typography>
        </Box>

        <Typography paragraph={true}>
          8. De consument draagt geen kosten voor de volledige of gedeeltelijke
          levering van niet op een materiële drager geleverde digitale inhoud,
          indien:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. hij voorafgaand aan de levering ervan niet uitdrukkelijk heeft
            ingestemd met het beginnen van de nakoming van de overeenkomst voor
            het einde van de bedenktijd;
          </Typography>

          <Typography paragraph={true}>
            b. hij niet heeft erkend zijn herroepingsrecht te verliezen bij het
            verlenen van zijn toestemming; of
          </Typography>

          <Typography paragraph={true}>
            c. de ondernemer heeft nagelaten deze verklaring van de consument te
            bevestigen.
          </Typography>
        </Box>

        <Typography paragraph={true}>
          9. Als de consument gebruik maakt van zijn herroepingsrecht, worden
          alle aanvullende overeenkomsten van rechtswege ontbonden.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 9 - Verplichtingen van de ondernemer bij herroeping
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Als de ondernemer de melding van herroeping door de consument op
          elektronische wijze mogelijk maakt, stuurt hij na ontvangst van deze
          melding onverwijld een ontvangstbevestiging.
        </Typography>

        <Typography paragraph={true}>
          2. De ondernemer vergoedt alle betalingen van de consument, inclusief
          eventuele leveringskosten door de ondernemer in rekening gebracht voor
          het geretourneerde product, onverwijld doch binnen 14 dagen volgend op
          de dag waarop de consument hem de herroeping meldt. Tenzij de
          ondernemer aanbiedt het product zelf af te halen, mag hij wachten met
          terugbetalen tot hij het product heeft ontvangen of tot de consument
          aantoont dat hij het product heeft teruggezonden, naar gelang welk
          tijdstip eerder valt.
        </Typography>

        <Typography paragraph={true}>
          3. De ondernemer gebruikt voor terugbetaling hetzelfde betaalmiddel
          dat de consument heeft gebruikt, tenzij de consument instemt met een
          andere methode. De terugbetaling is kosteloos voor de consument.
        </Typography>

        <Typography paragraph={true}>
          4. Als de consument heeft gekozen voor een duurdere methode van
          levering dan de goedkoopste standaardlevering, hoeft de ondernemer de
          bijkomende kosten voor de duurdere methode niet terug te betalen.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 10 - Uitsluiting herroepingsrecht
      </Typography>

      <Typography paragraph={true}>
        De ondernemer kan de navolgende producten en diensten uitsluiten van het
        herroepingsrecht, maar alleen als de ondernemer dit duidelijk bij het
        aanbod, althans tijdig voor het sluiten van de overeenkomst, heeft
        vermeld:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Producten of diensten waarvan de prijs gebonden is aan
          schommelingen op de financiële markt waarop de ondernemer geen invloed
          heeft en die zich binnen de herroepingstermijn kunnen voordoen;
        </Typography>

        <Typography paragraph={true}>
          2. Overeenkomsten die gesloten zijn tijdens een openbare veiling.
          Onder een openbare veiling wordt verstaan een verkoopmethode waarbij
          producten, digitale inhoud en/of diensten door de ondernemer worden
          aangeboden aan de consument die persoonlijk aanwezig is of de
          mogelijkheid krijgt persoonlijk aanwezig te zijn op de veiling, onder
          leiding van een veilingmeester, en waarbij de succesvolle bieder
          verplicht is de producten, digitale inhoud en/of diensten af te nemen;
        </Typography>

        <Typography paragraph={true}>
          3. Dienstenovereenkomsten, na volledige uitvoering van de dienst, maar
          alleen als:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. de uitvoering is begonnen met uitdrukkelijke voorafgaande
            instemming van de consument; en
          </Typography>

          <Typography paragraph={true}>
            b. de consument heeft verklaard dat hij zijn herroepingsrecht
            verliest zodra de ondernemer de overeenkomst volledig heeft
            uitgevoerd;
          </Typography>
        </Box>

        <Typography paragraph={true}>
          4. Pakketreizen als bedoeld in artikel 7:500 BW en overeenkomsten van
          personenvervoer;
        </Typography>

        <Typography paragraph={true}>
          5. Dienstenovereenkomsten voor terbeschikkingstelling van
          accommodatie, als in de overeenkomst een bepaalde datum of periode van
          uitvoering is voorzien en anders dan voor woondoeleinden,
          goederenvervoer, autoverhuurdiensten en catering;
        </Typography>

        <Typography paragraph={true}>
          6. Overeenkomsten met betrekking tot vrijetijdsbesteding, als in de
          overeenkomst een bepaalde datum of periode van uitvoering daarvan is
          voorzien;
        </Typography>

        <Typography paragraph={true}>
          7. Volgens specificaties van de consument vervaardigde producten, die
          niet geprefabriceerd zijn en die worden vervaardigd op basis van een
          individuele keuze of beslissing van de consument, of die duidelijk
          voor een specifieke persoon bestemd zijn;
        </Typography>

        <Typography paragraph={true}>
          8. Producten die snel bederven of een beperkte houdbaarheid hebben;
        </Typography>

        <Typography paragraph={true}>
          9. Verzegelde producten die om redenen van gezondheidsbescherming of
          hygiëne niet geschikt zijn om te worden teruggezonden en waarvan de
          verzegeling na levering is verbroken;
        </Typography>

        <Typography paragraph={true}>
          10. Producten die na levering door hun aard onherroepelijk vermengd
          zijn met andere producten;
        </Typography>

        <Typography paragraph={true}>
          11. Alcoholische dranken waarvan de prijs is overeengekomen bij het
          sluiten van de overeenkomst, maar waarvan de levering slechts kan
          plaatsvinden na 30 dagen, en waarvan de werkelijke waarde afhankelijk
          is van schommelingen van de markt waarop de ondernemer geen invloed
          heeft;
        </Typography>

        <Typography paragraph={true}>
          12. Verzegelde audio-, video-opnamen en computerprogrammatuur, waarvan
          de verzegeling na levering is verbroken;
        </Typography>

        <Typography paragraph={true}>
          13. Kranten, tijdschriften of magazines, met uitzondering van
          abonnementen hierop;
        </Typography>

        <Typography paragraph={true}>
          14. De levering van digitale inhoud anders dan op een materiële
          drager, maar alleen als:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. de uitvoering is begonnen met uitdrukkelijke voorafgaande
            instemming van de consument; en
          </Typography>

          <Typography paragraph={true}>
            b. de consument heeft verklaard dat hij hiermee zijn
            herroepingsrecht verliest.
          </Typography>
        </Box>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 11 - De prijs
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Gedurende de in het aanbod vermelde geldigheidsduur worden de
          prijzen van de aangeboden producten en/of diensten niet verhoogd,
          behoudens prijswijzigingen als gevolg van veranderingen in
          btw-tarieven.
        </Typography>

        <Typography paragraph={true}>
          2. In afwijking van het vorige lid kan de ondernemer producten of
          diensten waarvan de prijzen gebonden zijn aan schommelingen op de
          financiële markt en waar de ondernemer geen invloed op heeft, met
          variabele prijzen aanbieden. Deze gebondenheid aan schommelingen en
          het feit dat eventueel vermelde prijzen richtprijzen zijn, worden bij
          het aanbod vermeld.
        </Typography>

        <Typography paragraph={true}>
          3. Prijsverhogingen binnen 3 maanden na de totstandkoming van de
          overeenkomst zijn alleen toegestaan indien zij het gevolg zijn van
          wettelijke regelingen of bepalingen.
        </Typography>

        <Typography paragraph={true}>
          4. Prijsverhogingen vanaf 3 maanden na de totstandkoming van de
          overeenkomst zijn alleen toegestaan indien de ondernemer dit bedongen
          heeft en:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            a. deze het gevolg zijn van wettelijke regelingen of bepalingen; of
          </Typography>

          <Typography paragraph={true}>
            b. de consument de bevoegdheid heeft de overeenkomst op te zeggen
            met ingang van de dag waarop de prijsverhoging ingaat.
          </Typography>
        </Box>

        <Typography paragraph={true}>
          5. De in het aanbod van producten of diensten genoemde prijzen zijn
          inclusief btw.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 12 - Nakoming overeenkomst en extra garantie
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De ondernemer staat er voor in dat de producten en/of diensten
          voldoen aan de overeenkomst, de in het aanbod vermelde specificaties,
          aan de redelijke eisen van deugdelijkheid en/of bruikbaarheid en de op
          de datum van de totstandkoming van de overeenkomst bestaande
          wettelijke bepalingen en/of overheidsvoorschriften. Indien
          overeengekomen staat de ondernemer er tevens voor in dat het product
          geschikt is voor ander dan normaal gebruik.
        </Typography>

        <Typography paragraph={true}>
          2. Een door de ondernemer, diens toeleverancier, fabrikant of
          importeur verstrekte extra garantie beperkt nimmer de wettelijke
          rechten en vorderingen die de consument op grond van de overeenkomst
          tegenover de ondernemer kan doen gelden indien de ondernemer is
          tekortgeschoten in de nakoming van zijn deel van de overeenkomst.
        </Typography>

        <Typography paragraph={true}>
          3. Onder extra garantie wordt verstaan iedere verbintenis van de
          ondernemer, diens toeleverancier, importeur of producent waarin deze
          aan de consument bepaalde rechten of vorderingen toekent die verder
          gaan dan waartoe deze wettelijk verplicht is in geval hij is
          tekortgeschoten in de nakoming van zijn deel van de overeenkomst.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 13 - Levering en uitvoering
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De ondernemer zal de grootst mogelijke zorgvuldigheid in acht nemen
          bij het in ontvangst nemen en bij de uitvoering van bestellingen van
          producten en bij de beoordeling van aanvragen tot verlening van
          diensten.
        </Typography>

        <Typography paragraph={true}>
          2. Als plaats van levering geldt het adres dat de consument aan de
          ondernemer kenbaar heeft gemaakt.
        </Typography>

        <Typography paragraph={true}>
          3. Met inachtneming van hetgeen hierover in artikel 4 van deze
          algemene voorwaarden is vermeld, zal de ondernemer geaccepteerde
          bestellingen met bekwame spoed doch uiterlijk binnen 30 dagen
          uitvoeren, tenzij een andere leveringstermijn is overeengekomen.
          Indien de bezorging vertraging ondervindt, of indien een bestelling
          niet dan wel slechts gedeeltelijk kan worden uitgevoerd, ontvangt de
          consument hiervan uiterlijk 30 dagen nadat hij de bestelling geplaatst
          heeft bericht. De consument heeft in dat geval het recht om de
          overeenkomst zonder kosten te ontbinden en recht op eventuele
          schadevergoeding.
        </Typography>

        <Typography paragraph={true}>
          4. Na ontbinding conform het vorige lid zal de ondernemer het bedrag
          dat de consument betaald heeft onverwijld terugbetalen.
        </Typography>

        <Typography paragraph={true}>
          5. Het risico van beschadiging en/of vermissing van producten berust
          bij de ondernemer tot het moment van bezorging aan de consument of een
          vooraf aangewezen en aan de ondernemer bekend gemaakte
          vertegenwoordiger, tenzij uitdrukkelijk anders is overeengekomen.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 14 - Duurtransacties: duur, opzegging en verlenging
      </Typography>

      <Typography variant="h5" component="h3" paragraph={true}>
        Opzegging:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De consument kan een overeenkomst die voor onbepaalde tijd is
          aangegaan en die strekt tot het geregeld afleveren van producten
          (elektriciteit daaronder begrepen) of diensten, te allen tijde
          opzeggen met inachtneming van daartoe overeengekomen opzeggingsregels
          en een opzegtermijn van ten hoogste één maand.
        </Typography>

        <Typography paragraph={true}>
          2. De consument kan een overeenkomst die voor bepaalde tijd is
          aangegaan en die strekt tot het geregeld afleveren van producten
          (elektriciteit daaronder begrepen) of diensten, te allen tijde tegen
          het einde van de bepaalde duur opzeggen met inachtneming van daartoe
          overeengekomen opzeggingsregels en een opzegtermijn van ten hoogste
          één maand.
        </Typography>

        <Typography paragraph={true}>
          3. De consument kan de in de vorige leden genoemde overeenkomsten:
        </Typography>

        <Box ml={2}>
          <Typography paragraph={true}>
            - te allen tijde opzeggen en niet beperkt worden tot opzegging op
            een bepaald tijdstip of in een bepaalde periode;
          </Typography>

          <Typography paragraph={true}>
            - tenminste opzeggen op dezelfde wijze als zij door hem zijn
            aangegaan;
          </Typography>

          <Typography paragraph={true}>
            - altijd opzeggen met dezelfde opzegtermijn als de ondernemer voor
            zichzelf heeft bedongen.
          </Typography>
        </Box>
      </Box>

      <Typography variant="h5" component="h3" paragraph={true}>
        Verlenging:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          4. Een overeenkomst die voor bepaalde tijd is aangegaan en die strekt
          tot het geregeld afleveren van producten (elektriciteit daaronder
          begrepen) of diensten, mag niet stilzwijgend worden verlengd of
          vernieuwd voor een bepaalde duur.
        </Typography>

        <Typography paragraph={true}>
          5. In afwijking van het vorige lid mag een overeenkomst die voor
          bepaalde tijd is aangegaan en die strekt tot het geregeld afleveren
          van dag- nieuws- en weekbladen en tijdschriften stilzwijgend worden
          verlengd voor een bepaalde duur van maximaal drie maanden, als de
          consument deze verlengde overeenkomst tegen het einde van de
          verlenging kan opzeggen met een opzegtermijn van ten hoogste één
          maand.
        </Typography>

        <Typography paragraph={true}>
          6. Een overeenkomst die voor bepaalde tijd is aangegaan en die strekt
          tot het geregeld afleveren van producten of diensten, mag alleen
          stilzwijgend voor onbepaalde duur worden verlengd als de consument te
          allen tijde mag opzeggen met een opzegtermijn van ten hoogste één
          maand. De opzegtermijn is ten hoogste drie maanden in geval de
          overeenkomst strekt tot het geregeld, maar minder dan eenmaal per
          maand, afleveren van dag-, nieuws- en weekbladen en tijdschriften.
        </Typography>

        <Typography paragraph={true}>
          7. Een overeenkomst met beperkte duur tot het geregeld ter
          kennismaking afleveren van dag-, nieuws- en weekbladen en
          tijdschriften (proef- of kennismakingsabonnement) wordt niet
          stilzwijgend voortgezet en eindigt automatisch na afloop van de proef-
          of kennismakingsperiode.
        </Typography>
      </Box>

      <Typography variant="h5" component="h3" paragraph={true}>
        Duur:
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          8. Als een overeenkomst een duur van meer dan een jaar heeft, mag de
          consument na een jaar de overeenkomst te allen tijde met een
          opzegtermijn van ten hoogste één maand opzeggen, tenzij de
          redelijkheid en billijkheid zich tegen opzegging vóór het einde van de
          overeengekomen duur verzetten.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 15 - Betaling
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Voor zover niet anders is bepaald in de overeenkomst of aanvullende
          voorwaarden, dienen de door de consument verschuldigde bedragen te
          worden voldaan binnen 14 dagen na het ingaan van de bedenktermijn, of
          bij het ontbreken van een bedenktermijn binnen 14 dagen na het sluiten
          van de overeenkomst. In geval van een overeenkomst tot het verlenen
          van een dienst, vangt deze termijn aan op de dag nadat de consument de
          bevestiging van de overeenkomst heeft ontvangen.
        </Typography>

        <Typography paragraph={true}>
          2. Bij de verkoop van producten aan consumenten mag de consument in
          algemene voorwaarden nimmer verplicht worden tot vooruitbetaling van
          meer dan 50%. Wanneer vooruitbetaling is bedongen, kan de consument
          geen enkel recht doen gelden aangaande de uitvoering van de
          desbetreffende bestelling of dienst(en), alvorens de bedongen
          vooruitbetaling heeft plaatsgevonden.
        </Typography>

        <Typography paragraph={true}>
          3. De consument heeft de plicht om onjuistheden in verstrekte of
          vermelde betaalgegevens onverwijld aan de ondernemer te melden.
        </Typography>

        <Typography paragraph={true}>
          4. Indien de consument niet tijdig aan zijn betalingsverplichting(en)
          voldoet, is deze, nadat hij door de ondernemer is gewezen op de te
          late betaling en de ondernemer de consument een termijn van 14 dagen
          heeft gegund om alsnog aan zijn betalingsverplichtingen te voldoen, na
          het uitblijven van betaling binnen deze 14-dagen-termijn, over het nog
          verschuldigde bedrag de wettelijke rente verschuldigd en is de
          ondernemer gerechtigd de door hem gemaakte buitengerechtelijke
          incassokosten in rekening te brengen. Deze incassokosten bedragen
          maximaal: 15% over openstaande bedragen tot € 2.500,=; 10% over de
          daaropvolgende € 2.500,= en 5% over de volgende € 5.000,= met een
          minimum van € 40,=. De ondernemer kan ten voordele van de consument
          afwijken van genoemde bedragen en percentages.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 16 - Klachtenregeling
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. De ondernemer beschikt over een voldoende bekend gemaakte
          klachtenprocedure en behandelt de klacht overeenkomstig deze
          klachtenprocedure.
        </Typography>

        <Typography paragraph={true}>
          2. Klachten over de uitvoering van de overeenkomst moeten binnen
          bekwame tijd nadat de consument de gebreken heeft geconstateerd,
          volledig en duidelijk omschreven worden ingediend bij de ondernemer.
        </Typography>

        <Typography paragraph={true}>
          3. Bij de ondernemer ingediende klachten worden binnen een termijn van
          14 dagen gerekend vanaf de datum van ontvangst beantwoord. Als een
          klacht een voorzienbaar langere verwerkingstijd vraagt, wordt door de
          ondernemer binnen de termijn van 14 dagen geantwoord met een bericht
          van ontvangst en een indicatie wanneer de consument een meer uitvoerig
          antwoord kan verwachten.
        </Typography>

        <Typography paragraph={true}>
          4. De consument dient de ondernemer in ieder geval 4 weken de tijd te
          geven om de klacht in onderling overleg op te lossen. Na deze termijn
          ontstaat een geschil dat vatbaar is voor de geschillenregeling.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 17 - Geschillen
      </Typography>

      <Box ml={2}>
        <Typography paragraph={true}>
          1. Op overeenkomsten tussen de ondernemer en de consument waarop deze
          algemene voorwaarden betrekking hebben, is uitsluitend Nederlands
          recht van toepassing.
        </Typography>
      </Box>

      <Typography variant="h2" gutterBottom>
        Artikel 18 - Aanvullende of afwijkende bepalingen
      </Typography>

      <Typography paragraph={true}>
        Aanvullende dan wel van deze algemene voorwaarden afwijkende bepalingen
        mogen niet ten nadele van de consument zijn en dienen schriftelijk te
        worden vastgelegd dan wel op zodanige wijze dat deze door de consument
        op een toegankelijke manier kunnen worden opgeslagen op een duurzame
        gegevensdrager.
      </Typography>

      <Typography variant="h2" gutterBottom>
        Bijlage I - Modelformulier voor herroeping
      </Typography>

      <Typography paragraph={true}>
        De consument kan het modelformulier voor herroeping{" "}
        <Link
          href="/modelformulier_voor_herroeping.docx"
          underline="always"
          target="_blank"
        >
          hier
        </Link>{" "}
        downloaden en dient deze per e-mail te versturen naar info@todocards.nl.
        Daarnaast kan de consument het modelformulier per briefpost versturen
        naar:
      </Typography>

      <Typography paragraph={true}>
        Todocards.nl
        <br />
        Mr. Tripkade 18
        <br />
        3571 SX Utrecht
        <br />
        Nederland
      </Typography>

      <Typography paragraph={true}>
        Naast het modelformulier kan de consument de overeenkomst herroepen door
        een e-mail te sturen naar info@todocards.nl, via het{" "}
        <Link component={GatsbyLink} underline="always" to="/contact">
          contactformulier
        </Link>{" "}
        of door onderstaand formulier in te vullen.
      </Typography>

      <ReturnForm />
    </MainShell>
  )
}

export default TermsPage
