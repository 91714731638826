import yup from "../../utils/get-yup"

export const validationSchema = yup.object().shape({
  name: yup.string().required(),
  email: yup
    .string()
    .required()
    .email(),
  orderRef: yup.string().required(),
  products: yup.string().required(),
})
