import React from "react"

import { useForm, FormContext } from "react-hook-form"

import { validationSchema } from "./return-form-validation"
import { FormValues } from "./return-form-types"
import TextField from "../utils/form/text-field/text-field-view"
import SubmitButton from "../utils/form/submit-button/submit-button-view"
import { ViewDispatchActions } from "./return-form-types"
import { createReturnRequest } from "../../api/actions"

const ReturnFormView = ({ showAlert }: ViewDispatchActions) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      name: ``,
      email: ``,
      orderRef: ``,
      products: ``,
      reason: ``,
    },
    mode: `onBlur`,
    validationSchema,
  })

  const onSubmit = async (data: FormValues) => {
    await createReturnRequest(
      data.name,
      data.email,
      data.orderRef,
      data.products,
      data.reason,
      `De retouraanvraag kon niet verzonden worden, er is een onbekende fout opgetreden.`,
      `De retouraanvraag kon niet verzonden worden, controleer de verbinding met internet.`
    ).then(() => {
      methods.reset()

      showAlert(
        `Retouraanvraag verzonden`,
        `De retouraanvraag is succesvol verzonden. We zullen zo snel mogelijk een reactie sturen naar het door jou opgegeven e-mailadres.`
      )
    })
  }

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()

    methods.triggerValidation().then(isValid => {
      if (!isValid) {
        showAlert(
          `Er is een fout opgetreden`,
          `De retouraanvraag kon niet verzonden worden, niet alle velden zijn juist ingevuld.`
        )
      }

      methods.handleSubmit(onSubmit)()
    })
  }

  return (
    <FormContext {...methods}>
      <form onSubmit={handleSubmit}>
        <TextField name="name" label="Naam" />
        <TextField name="email" label="E-mailadres" />
        <TextField name="orderRef" label="Bestelnummer" />
        <TextField
          name="products"
          label="Welk(e) product(en) wil je retourneren?"
        />
        <TextField
          name="reason"
          label="Reden van retourneren (optioneel)"
          multiline
        />

        <SubmitButton
          label="Verzenden"
          isSubmitting={methods.formState.isSubmitting}
        />
      </form>
    </FormContext>
  )
}

export default ReturnFormView
